import { organisationQueryKeys } from "./queryKeys";

import { useQuery } from "@tanstack/react-query";
import { getOrganisationDetails } from "./request";
import useOrganisationId from "hooks/session/useOrganisationId";

export const useGetOrganisationDetails = () => {
  const organisationId = useOrganisationId();
  const queryKey = organisationQueryKeys.details(organisationId);
  const query = useQuery({
    queryKey,
    queryFn: () => getOrganisationDetails(organisationId),
  });
  return { query, queryKey };
};
